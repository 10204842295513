import {
  CurrencyDollarIcon,
  TagIcon,
  TvIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import type {
  ContractPricingType,
  EmojiType,
  LocationPropertyType,
  ManagerAccountVendorBusinessCriticalityRating,
  ManagerAccountVendorCybersecurityOptions,
  ManagerAccountVendorContactType,
  ManagerAccountVendorDocumentType,
  ManagerAccountVendorIncidentImpact,
  ManagerAccountVendorIncidentStatus,
  ManagerAccountVendorRiskScore,
  TaskCompletionStatus,
  TaskScheduleStatus,
  ContractLineItemPriceCadence,
  LocationClass,
  LocationPropertyStage,
  ExtendWorkflowClassificationType,
  VendorValueTags,
  VendorCompanyStatus,
  VendorLastFundingRound,
  LocationRoleType,
  ManagerAccountRoleType,
  ProductCategoryStatus,
  ZiplineStatus,
  ContractLineItemZiplineStatusLocations,
  ExtendWorkflowStatus,
  VendorState,
  ProductState,
  GeneralLedgerImportStandard,
  DepartmentState,
  ComplianceProgramCredentialStatus,
  ESignWorkflowRunStatus,
  LocationStatus,
  ZiplineModuleVersion,
  RviVendorContactTag,
  ContractExecutionStatus,
  ContractExecutionRejectionReason,
  ContractTemplateVariableAmountFormat,
  ProductAwardType,
  ResidentLifecyclePhase,
} from "prisma-types";
import type { LocationNoticeType } from "./location-notice.utils";

export const MB = 1024 * 1024;

export const FALLBACK_UUID = "00000000-0000-0000-0000-000000000000";

export const DEFAULT_FORM_ERROR_MESSAGE =
  "There were problems submitting your form. Please try again";

export const PAID_PLANS = [
  "Community Builder",
  "Brand Builder",
  "Market Builder",
];

export const DEFAULT_VENDOR_RATING = {
  rating: 0,
  totalReviews: 0,
};

export const IMAGE_EXTENSIONS = ["jpg", "jpeg", "gif", "png", "webp"];
export const VIDEO_EXTENSIONS = [
  "webm",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "ogg",
  "mp4",
  "m4p",
  "m4v",
  "avi",
  "wmv",
  "mov",
  "qt",
  "flv",
  "swf",
  "avchd",
];

export const ManagerAccountVendorContactTypeLabels: Record<
  ManagerAccountVendorContactType | "PMS",
  string
> = {
  Main: "Main Contact",
  Support: "Support Contact",
  Billing: "Billing Contact",
  Internal: "Internal Relationship",
  Disposition: "Cancellation Notice Contact",
  PMS: "PMS Contact (System)",
} as const;

export const ManagerAccountVendorCybersecurityLabels: Record<
  ManagerAccountVendorCybersecurityOptions,
  string
> = {
  NistCybersecurityFramework: "NIST Cybersecurity Framework",
  Iso27000Series: "ISO 27000 Series",
  Soc2Type1: "SOC 2 Type 1",
  Soc2Type2: "SOC 2 Type 2",
  PciDss: "PCI-DSS",
  NercCip: "NERC CIP",
  Cobit: "COBIT",
  Fisma: "FISMA",
  Ofdss: "OFDSS",
  Other: "Other",
  NA: "N/A",
} as const;

export const ROLES_ALLOWED_TO_LOCATION_LEVEL_ROLE: ManagerAccountRoleType[] = [
  "CorporateEditor",
  "CorporateViewer",
  "Member",
];

export const ManagerAccountVendorRiskScoreLabels: Record<
  ManagerAccountVendorRiskScore,
  string
> = {
  NotSet: "Not Set",
  Zero: "Zero",
  Low: "Low",
  Medium: "Medium",
  High: "High",
  VeryHigh: "Very High",
};

export const ManagerAccountVendorBusinessCriticalityRatingLabels: Record<
  ManagerAccountVendorBusinessCriticalityRating,
  string
> = {
  NotSet: "Not Set",
  Zero: "Zero: No Consequential Impact",
  Low: "Low: Administrative or Desirable",
  Medium: "Medium: Operational or Necessary",
  High: "High: Business Critical or Essential",
  VeryHigh: "Very High: Mission Critical",
} as const;

export const ManagerAccountVendorDocumentTypeLabels: Record<
  ManagerAccountVendorDocumentType,
  string
> = {
  CybersecurityReport: "Cybersecurity Report",
  DataPrivacyAgreement: "Data Privacy Agreement",
  Insurance: "Insurance",
  ITQuestionnaire: "IT Questionnaire",
  PrivacyPolicy: "Privacy Policy",
  Proposal: "Proposal",
  SalesAndMarketingMaterials: "Sales & Marketing Materials",
  ScopeOfWork: "Scope of Work",
  TermsofService: "Terms of Service",
  UtilityDocument: "Utility Document",
  W9: "W9",
  Other: "Other",
} as const;

export const ContractPricingTypeLabels: Record<ContractPricingType, string> = {
  FlatRate: "Flat Rate",
  Itemized: "Itemized",
  PercentageBased: "Percentage Based",
  PerHour: "Per Hour",
  PerLease: "Per Lease (per location)",
  PerLocation: "Per Location",
  PerResident: "Per Resident (per location)",
  PerSeat: "Per Seat",
  PerUnit: "Per Unit (per location)",
  UsageBased: "Usage Based",
};

export const ContractPricingTypeShortLabels: Record<
  ContractPricingType,
  string
> = {
  ...ContractPricingTypeLabels,
  PerLease: "Per Lease",
  PerResident: "Per Resident",
  PerUnit: "Per Unit",
};

export const ManagerAccountVendorIncidentStatusLabels: Record<
  ManagerAccountVendorIncidentStatus,
  string
> = {
  New: "New",
  InProgress: "In Progress",
  UnderInvestigation: "Under Investigation",
  Resolved: "Resolved",
};

export const ManagerAccountVendorIncidentImpactLabels: Record<
  ManagerAccountVendorIncidentImpact,
  string
> = {
  NotSet: "Not Set",
  Zero: "Zero",
  Low: "Low",
  Medium: "Medium",
  High: "High",
  VeryHigh: "Very High",
};

export const LocationPropertyTypeLabels: Record<LocationPropertyType, string> =
  {
    Affordable: "Affordable",
    BuildToRent: "Build to Rent",
    ConventionalMultifamily: "Conventional Multifamily",
    OfficeCommercial: "Office Commercial",
    Other: "Other",
    Student: "Student",
    Senior: "Senior",
    SelfStorage: "Self Storage",
    SingleFamily: "Single Family",
  } as const;

export const LocationClassLabels: Record<LocationClass, string> = {
  APlus: "A+",
  A: "A",
  AMinus: "A-",
  BPlus: "B+",
  B: "B",
  BMinus: "B-",
  CPlus: "C+",
  C: "C",
  CMinus: "C-",
  DPlus: "D+",
  D: "D",
  DMinus: "D-",
} as const;

export const LocationPropertyStageLabels: Record<
  LocationPropertyStage,
  string
> = {
  LeaseUp: "Lease Up",
  Stabilized: "Stabilized",
  Renovation: "Renovation",
  Other: "Other",
} as const;

export const TaskScheduleStatusLabels: Record<TaskScheduleStatus, string> = {
  Upcoming: "Upcoming",
  ReadyToDo: "Ready to Do",
  Overdue: "Overdue",
  Archived: "Archived",
};

export const TaskCompletionStatusLabels: Record<TaskCompletionStatus, string> =
  {
    Incomplete: "Incomplete",
    Completed: "Completed",
    Canceled: "Canceled",
  };

export const EmojiTypeValues: Record<EmojiType, string> = {
  Thumbsup: "👍",
  Heart: "❤️",
  Joy: "😂",
  WhiteCheckMark: "✅",
  Tada: "🎉",
};

export const VendorCompanyStatusLabels: Record<VendorCompanyStatus, string> = {
  Private: "Private",
  Public: "Public",
  Other: "Other",
} as const;

export const VendorLastFundingRoundLabels: Record<
  VendorLastFundingRound,
  string
> = {
  NotSet: "Not Set",
  None: "None",
  PreSeed: "Pre-Seed",
  Seed: "Seed",
  SeriesA: "Series A",
  SeriesB: "Series B",
  SeriesC: "Series C",
  SeriesDPlus: "Series D+",
  VentureRound: "Venture Round",
  PrivateEquityRound: "Private Equity Round",
  DebtFinancing: "Debt Financing",
  SecondaryMarket: "Secondary Market",
  IPO: "IPO",
  PostIPOEquity: "Post-IPO Equity",
  PostIPODebt: "Post-IPO Debt",
  Other: "Other",
} as const;

export const VendorTotalRoundsLabels: Record<number, string> = {
  0: "None",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  11: "11",
  12: "12",
  13: "13",
  14: "14",
  15: "15",
  16: "16",
  17: "17",
  18: "18",
  19: "19",
  20: "20",
} as const;

export const VendorValueTagsLabels: Record<VendorValueTags, string> = {
  MinorityWomenOwned: "Minority/Women-Owned",
  RevyseForStartups: "Revyse for Startups",
  FoundationsForRentalHousing: "Foundations for Rental Housing",
} as const;

export const ExtendWorkflowClassificationTypeLabels: Record<
  ExtendWorkflowClassificationType,
  string
> = {
  CONTRACT: "Contract",
  ORDER_FORM: "Order Form",
  ADDENDUM: "Addendum",
  AMENDMENT: "Amendment",
  COI: "COI",
  W9: "W9",
  PMA: "PMA",
  OTHER: "Other",
} as const;

export const enum PRODUCT_PERKS_KEYS {
  promos = "promos",
  demos = "demos",
  pricing = "pricing",
  videos = "videos",
}

export const ProductPerksLabels: Record<
  keyof typeof PRODUCT_PERKS_KEYS,
  string
> = {
  promos: "Promos and discounts",
  demos: "Product demos",
  pricing: "Published pricing",
  videos: "Videos",
};

export const ProductPerksIcons: Record<
  keyof typeof PRODUCT_PERKS_KEYS,
  JSX.ElementType
> = {
  promos: TagIcon,
  demos: TvIcon,
  pricing: CurrencyDollarIcon,
  videos: VideoCameraIcon,
};

export const ContractLineItemPriceCadenceLabels: Record<
  ContractLineItemPriceCadence,
  string
> = {
  Weekly: "Weekly",
  Monthly: "Monthly",
  Quarterly: "Quarterly",
  Annual: "Annual",
  OneTime: "One Time",
};

export const LocationNoticeTypeLabels: Record<LocationNoticeType, string> = {
  LocationNoticeDisposition: "Disposition Notice",
  LocationServiceTermination: "Service Termination Notice",
};

export const LocationRoleTypeLabels: Record<LocationRoleType, string> = {
  Editor: "Location Editor",
  Viewer: "Location Viewer",
};

export const ManagerAccountRoleTypeLabels: Record<
  ManagerAccountRoleType,
  string
> = {
  CompanyOwner: "Company Owner",
  CompanyAdmin: "Company Admin",
  CompanyEditor: "Company Editor",
  CorporateEditor: "Corporate Editor",
  CorporateViewer: "Corporate Viewer",
  Member: "Member",
};

export enum IntelligenceLevel {
  Corporate = "corporate",
  Location = "location",
}

export const IntelligenceLevelLabels: Record<IntelligenceLevel, string> = {
  [IntelligenceLevel.Corporate]: "Corporate-Managed",
  [IntelligenceLevel.Location]: "Location-Managed",
};

// In the future, compliance programs will include other requirements such as uploading an image, or uploading document, or filling out a request form.
export enum ComplianceRequirementType {
  ESign = "esign",
}

export const ComplianceRequirementTypeLabels: Record<
  ComplianceRequirementType,
  string
> = {
  [ComplianceRequirementType.ESign]: "eSign",
};

export const ProductCategoryStatusLabels: Record<
  ProductCategoryStatus,
  string
> = {
  ApprovedForPublishing: "Approved for Publishing on Revyse",
  RevyseIntelligenceOnly: "Revyse Intelligence-Only",
  NotApproved: "Not Approved",
};
export const ZiplineStatusLabels: Record<ZiplineStatus, string> = {
  PendingAnalysis: "Processing",
  Analyzing: "Processing",
  UnderReview: "Processing",
  AwaitingApproval: "Needs Review",
  Approved: "Approved",
  FailedAnalysis: "Failed",
  Canceled: "Canceled",
};

export const AdminZiplineStatusLabels: Record<ZiplineStatus, string> = {
  PendingAnalysis: "Processing",
  Analyzing: "Processing",
  UnderReview: "Processing",
  AwaitingApproval: "Ready for Review",
  Approved: "Approved",
  FailedAnalysis: "Processing",
  Canceled: "Canceled",
};

export const ExtendZiplineStatusLabels: Record<ExtendWorkflowStatus, string> = {
  PENDING: "In Progress",
  REJECTED: "Rejected",
  NEEDS_REVIEW: "Needs Extend Review",
  PROCESSED: "Processed",
  REVIEWED: "Reviewed",
  FAILED: "Failed",
};

export const ContractLineItemZiplineStatusLocationsLabels: Record<
  ContractLineItemZiplineStatusLocations,
  string
> = {
  Pending: "Needs Review",
  Approved: "Approved",
};

export const VendorStateLabels: Record<VendorState, string> = {
  ApprovedForPublishing: "Approved for Publishing",
  NotApproved: "Not Approved",
  RevyseIntelligenceOnly: "Revyse Intelligence-Only",
} as const;

export const ProductStateLabels: Record<ProductState, string> = {
  discovery: "Approved for Publishing on Revyse",
  intelligence: "Revyse Intelligence-Only",
} as const;

export const GeneralLedgerImportStandardLabels: Record<
  GeneralLedgerImportStandard,
  string
> = {
  Standard1: "Standard 1",
  Standard2: "YardiCorp",
  Standard3: "YardiL",
  VendorPaidSetup: "Vendor Paid Setup",
} as const;

export const DepartmentStateLabels: Record<DepartmentState, string> = {
  AnyContract: "Any contract",
  CorporateManagedContracts: "Corporate-managed contracts only",
  LocationManagedContracts: "Location-managed contracts only",
} as const;

export const ALLOWED_AVATAR_TYPES = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/webp",
];

export const CustomerComplianceProgramCredentialStatusLabels: Record<
  ComplianceProgramCredentialStatus,
  string
> = {
  NotStarted: "Not Started",
  InProgress: "In Progress",
  Complete: "Complete",
  Paused: "In Progress",
} as const;

export const RVIComplianceProgramCredentialStatusLabels: Record<
  ComplianceProgramCredentialStatus,
  string
> = {
  NotStarted: "Not Started",
  InProgress: "In Progress",
  Complete: "Complete",
  Paused: "Paused",
} as const;

export const CustomerESignWorkflowRunStatusLabels: Record<
  ESignWorkflowRunStatus,
  string
> = {
  QuestionSubmitted: "QuestionSubmitted",
  Rejected: "Declined",
  Accepted: "Viewed",
  Invited: "Not Started",
  Signed: "Complete",
  Expired: "Expired",
} as const;

export const RVIESignWorkflowRunStatusLabels: Record<
  ESignWorkflowRunStatus,
  string
> = {
  QuestionSubmitted: "Question Received",
  Rejected: "Declined",
  Accepted: "Viewed",
  Invited: "Not Started",
  Signed: "Complete",
  Expired: "Expired",
} as const;

export const LocationStatusLabels: Record<LocationStatus, string> = {
  Active: "Active",
  Pending: "Pending",
  Disposed: "Archived",
};

export const ZiplineModuleVersionLabels: Record<ZiplineModuleVersion, string> =
  {
    v1: "Zipline 1.0",
    v2: "Zipline 2.0",
  };

export const RviVendorContactTagLabels: Record<RviVendorContactTag, string> = {
  esigner: "e-Signer",
  pms: "PMS",
};

export const RVIContractExecutionStatusLabels: Record<
  ContractExecutionStatus,
  string
> = {
  Draft: "Draft",
  Sending: "Sending",
  DeliveryFailed: "Delivered Failed",
  Sent: "Sent",
  SignerUpdate: "Signer Update",
  Declined: "Declined",
  Rejected: "Rejected",
  VendorSigned: "Countersign",
  LinkExpired: "Link Expired",
  Voided: "Voided",
  Active: "Active",
} as const;

export const VendorContractExecutionStatusLabels: Record<
  Exclude<ContractExecutionStatus, "Draft" | "Sending" | "DeliveryFailed">,
  string
> = {
  Sent: "Pending Signature",
  SignerUpdate: "Signer Update",
  Declined: "Declined",
  Rejected: "Rejected",
  VendorSigned: "Pending Countersignature",
  LinkExpired: "Link Expired",
  Voided: "Voided",
  Active: "Complete",
} as const;

export const VendorContractExecutionRejectionReasonLabels: Record<
  ContractExecutionRejectionReason,
  string
> = {
  PricingDiscrepancy: "Pricing Discrepancy",
  TermsAndConditionsIssue: "Terms & Conditions Issue",
  ServiceScopeMismatch: "Service Scope Mismatch ",
  MissingInformation: "Missing Information",
  Other: "Other",
} as const;

export const ContractTemplateVariableAmountFormatLabels: Record<
  ContractTemplateVariableAmountFormat,
  string
> = {
  Number: "Number",
  Currency: "US Dollar Currency ($)",
  Percentage: "Percentage (%)",
} as const;

export const ProductAwardTypeLabels: Record<ProductAwardType, string> = {
  MostLoved: "Most Loved",
  SupportStar: "Support Star",
  BestValue: "Best Value",
} as const;

export const ResidentLifecyclePhaseLabels: Record<
  ResidentLifecyclePhase,
  string
> = {
  Shopping: "Shopping",
  ProfessionalServices: "Professional Services",
  Residency: "Residency",
  Application: "Application",
  EnterpriseCorporateTech: "Enterprise Corporate Tech",
  MoveOut: "Move-Out",
  MoveIn: "Move-In",
  Touring: "Consideration & Touring",
  EnterprisePropertyTech: "Enterprise Property Tech",
  Retention: "Retention",
  Leasing: "Lease",
  OnsiteSuppliers: "Onsite Suppliers",
  None: "None",
} as const;

export const ResidentLifecyclePhaseTooltips: Record<
  ResidentLifecyclePhase,
  string
> = {
  Shopping:
    "Advertising and marketing solutions that attract and convert renters (ILSs, syndication, SEO, branding, social media, websites and more).",
  ProfessionalServices:
    "Expert support and industry resources (advisory services, legal, IT, recruiting, consultants, trade shows, and more).",
  Residency:
    "Services and technology that enhance the resident experience and community operations (smart home tech, maintenance, package management, EV charging, utilities, valet trash, food delivery, and more).",
  Application:
    "Tools that streamline the leasing process (online leasing, e-signature, screening & verification).",
  EnterpriseCorporateTech:
    "Essential business software for operators (ERP systems, accounting, content management, help desk solutions, and more).",
  MoveOut:
    "Services that streamline the turnover process (rent recovery, collections, turn & make-ready services).",
  MoveIn:
    "Technology that streamlines the resident onboarding experience (access control, payment processing, resident apps, digital onboarding).",
  Touring:
    "Technology that engages and converts prospects (chatbots, CRMs, contact centers, email marketing, lead nurturing, leasing AI, touring solutions).",
  EnterprisePropertyTech:
    "Solutions for optimizing operations and investments (budgeting, BI and analytics, property management software, revenue management, vendor management, construction management, and more).",
  Retention:
    "Products and services that enhance resident satisfaction and loyalty (mystery shops, renewals, engagement surveys, rewards programs).",
  Leasing:
    "Services that support residents throughout their lease term (furniture rental, insurance and deposit programs, parking management, financial services, short-term rentals).",
  OnsiteSuppliers:
    "Essential products for property upkeep (maintenance supplies, flooring, paint, uniforms).",
  None: "None",
} as const;
